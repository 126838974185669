exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-buy-index-jsx": () => import("./../../../src/pages/buy/index.jsx" /* webpackChunkName: "component---src-pages-buy-index-jsx" */),
  "component---src-pages-community-index-jsx": () => import("./../../../src/pages/community/index.jsx" /* webpackChunkName: "component---src-pages-community-index-jsx" */),
  "component---src-pages-dag-explorer-index-jsx": () => import("./../../../src/pages/dag-explorer/index.jsx" /* webpackChunkName: "component---src-pages-dag-explorer-index-jsx" */),
  "component---src-pages-dag-token-index-tsx": () => import("./../../../src/pages/dag-token/index.tsx" /* webpackChunkName: "component---src-pages-dag-token-index-tsx" */),
  "component---src-pages-delegated-staking-index-tsx": () => import("./../../../src/pages/delegated-staking/index.tsx" /* webpackChunkName: "component---src-pages-delegated-staking-index-tsx" */),
  "component---src-pages-developer-hub-index-jsx": () => import("./../../../src/pages/developer-hub/index.jsx" /* webpackChunkName: "component---src-pages-developer-hub-index-jsx" */),
  "component---src-pages-digital-evidence-index-tsx": () => import("./../../../src/pages/digital-evidence/index.tsx" /* webpackChunkName: "component---src-pages-digital-evidence-index-tsx" */),
  "component---src-pages-dtm-index-jsx": () => import("./../../../src/pages/dtm/index.jsx" /* webpackChunkName: "component---src-pages-dtm-index-jsx" */),
  "component---src-pages-enterprise-index-jsx": () => import("./../../../src/pages/enterprise/index.jsx" /* webpackChunkName: "component---src-pages-enterprise-index-jsx" */),
  "component---src-pages-federal-index-jsx": () => import("./../../../src/pages/federal/index.jsx" /* webpackChunkName: "component---src-pages-federal-index-jsx" */),
  "component---src-pages-get-started-index-tsx": () => import("./../../../src/pages/get-started/index.tsx" /* webpackChunkName: "component---src-pages-get-started-index-tsx" */),
  "component---src-pages-hypergraph-index-jsx": () => import("./../../../src/pages/hypergraph/index.jsx" /* webpackChunkName: "component---src-pages-hypergraph-index-jsx" */),
  "component---src-pages-jobs-index-jsx": () => import("./../../../src/pages/jobs/index.jsx" /* webpackChunkName: "component---src-pages-jobs-index-jsx" */),
  "component---src-pages-lattice-index-jsx": () => import("./../../../src/pages/lattice/index.jsx" /* webpackChunkName: "component---src-pages-lattice-index-jsx" */),
  "component---src-pages-pacaswap-index-tsx": () => import("./../../../src/pages/pacaswap/index.tsx" /* webpackChunkName: "component---src-pages-pacaswap-index-tsx" */),
  "component---src-pages-snapshot-fee-calculator-index-jsx": () => import("./../../../src/pages/snapshot-fee-calculator/index.jsx" /* webpackChunkName: "component---src-pages-snapshot-fee-calculator-index-jsx" */),
  "component---src-pages-stargazer-wallet-index-jsx": () => import("./../../../src/pages/stargazer-wallet/index.jsx" /* webpackChunkName: "component---src-pages-stargazer-wallet-index-jsx" */),
  "component---src-pages-use-cases-index-jsx": () => import("./../../../src/pages/use-cases/index.jsx" /* webpackChunkName: "component---src-pages-use-cases-index-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-categories-jsx": () => import("./../../../src/templates/categories.jsx" /* webpackChunkName: "component---src-templates-categories-jsx" */),
  "component---src-templates-dor-nft-terms-page-jsx": () => import("./../../../src/templates/dor-nft-terms-page.jsx" /* webpackChunkName: "component---src-templates-dor-nft-terms-page-jsx" */),
  "component---src-templates-flight-program-page-jsx": () => import("./../../../src/templates/flight-program-page.jsx" /* webpackChunkName: "component---src-templates-flight-program-page-jsx" */),
  "component---src-templates-index-page-jsx": () => import("./../../../src/templates/index-page.jsx" /* webpackChunkName: "component---src-templates-index-page-jsx" */),
  "component---src-templates-privacy-policy-page-jsx": () => import("./../../../src/templates/privacy-policy-page.jsx" /* webpackChunkName: "component---src-templates-privacy-policy-page-jsx" */),
  "component---src-templates-projects-page-jsx": () => import("./../../../src/templates/projects-page.jsx" /* webpackChunkName: "component---src-templates-projects-page-jsx" */),
  "component---src-templates-stargazer-privacy-policy-page-jsx": () => import("./../../../src/templates/stargazer-privacy-policy-page.jsx" /* webpackChunkName: "component---src-templates-stargazer-privacy-policy-page-jsx" */),
  "component---src-templates-stargazer-terms-page-jsx": () => import("./../../../src/templates/stargazer-terms-page.jsx" /* webpackChunkName: "component---src-templates-stargazer-terms-page-jsx" */),
  "component---src-templates-tags-jsx": () => import("./../../../src/templates/tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */),
  "component---src-templates-team-page-jsx": () => import("./../../../src/templates/team-page.jsx" /* webpackChunkName: "component---src-templates-team-page-jsx" */),
  "component---src-templates-terms-of-service-page-jsx": () => import("./../../../src/templates/terms-of-service-page.jsx" /* webpackChunkName: "component---src-templates-terms-of-service-page-jsx" */)
}

